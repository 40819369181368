import { ANALYTICS } from 'constants/analytics'
import { useContext, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import Cookies from 'js-cookie'
import { FEATURE_TOGGLES, SESSION_BASED_FEATURE_TOGGLES } from 'constants/featureToggles'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import {
  CONSENT_BANNER_NAME_EXPERIMENT,
  PHONE_NUMBERS_NAME_EXPERIMENT,
} from 'constants/experiments'
import { AnalyticsContext } from 'providers/AnalyticsProvider'
import { removeUrlParams } from 'utils/shared'
import {
  createExperimentObject,
  shouldTrackPageLevelExperiment,
  shouldTrackSessionExperiment,
} from 'utils/experiments'

const baseObject = {
  event_origin: 'client',
}

const kebabCaseToPascalCase = (str) => {
  return str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

const getPagePath = (pathname) => {
  if (pathname === '/') return pathname
  if (pathname === undefined) return '/'
  // remove proceeding slash and ensure pathname does not include query string
  return pathname.substring(1).split('?')[0]
}

const waitForTealium = (fn) => {
  // ACX-145: Delay page track so Tealium data is present
  // It would be better to queue these events in the AnalyticsProvider,
  // but we need a way to watch window.utag.data.tealum_session_id
  // Yes we could queue until the next event or page render but what if there isn't one
  let delay = 2500
  if (window?.utag?.data?.tealium_session_id) {
    delay = 0
  }
  const timer = setTimeout(() => {
    fn()
  }, delay)
  return () => clearTimeout(timer)
}

/**
 * Constructs a tracking object for analytics purposes.
 *
 * @param {Object} config - Configuration object containing analytics settings.
 * @param {string} config.NEXT_PUBLIC_ANALYTICS_APP_NAME - The name of the analytics application.
 * @param {string} config.NEXT_PUBLIC_ANALYTICS_APP_TITLE - The title of the analytics application.
 * @param {string} config.NEXT_PUBLIC_ANALYTICS_ENV - The current environment/version of the application.
 * @param {Object} paths - Object containing page-related paths and URLs.
 * @param {string} paths.pagePath - The path of the current page.
 * @param {string} paths.referer - The URL of the referring page.
 * @param {string} paths.urlParams - Query parameters for the current page URL.
 * @param {string} paths.host - The host name of the current page.
 * @param {string} paths.fullPagePath - The full path of the current page.
 * @param {string} paths.pageRealPath - The actual path of the page, which may differ from `pagePath`.
 *
 * @returns {Object} A tracking object containing analytics data.
 */
const constructTrackingObject = (
  config = {},
  { pagePath, referer, urlParams, host, fullPagePath, pageRealPath } = {}
) => {
  // Determine the public page path, using `pageRealPath` if available.
  let pagePathPublic = pageRealPath
    ? pageRealPath === pagePath
      ? pagePath
      : pageRealPath
    : pagePath

  // If the public page path contains dynamic parameters (e.g., /[param]), revert to using `pagePath`.
  if (pagePathPublic?.includes('/[')) {
    pagePathPublic = pagePath
  }

  // Construct the page name for analytics, combining the app name and page title.
  const page_name = `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${
    pagePath === '/' || !pagePath
      ? config.NEXT_PUBLIC_ANALYTICS_APP_TITLE
      : kebabCaseToPascalCase(pagePathPublic)
  }`

  // Construct the full URL of the current page.
  const page_url = `https://${host}/${pagePath !== '/' ? pagePath : ''}`

  // Return the tracking object with various analytics properties.
  return {
    page_name,
    event_name: `${page_name} - Page Viewed`,
    browser_id: Cookies.get(ANALYTICS.BROWSER_ID_KEY_NAME),
    session_id: sessionStorage.getItem(ANALYTICS.SESSION_ID_KEY_NAME),
    application_version: config.NEXT_PUBLIC_ANALYTICS_ENV,
    referring_url: referer || page_url,
    url_params: urlParams,
    page_title:
      pagePath === '/' || !pagePath
        ? config.NEXT_PUBLIC_ANALYTICS_APP_TITLE
        : kebabCaseToPascalCase(pagePathPublic),
    page_path: `${config.NEXT_PUBLIC_ANALYTICS_APP_NAME} | ${removeUrlParams(pagePath)}`,
    page_url: page_url,
    full_page_url: `https://${host}${fullPagePath}`,
  }
}

/**
 * ACX-194
 *
 * Handle track and react events
 * This allows you to have a track prop and/or onClick prop
 * If you have both a track and onClick prop,
 * they will be called in parallel
 *
 * If track is a boolean then the default track behavior will be used
 * If track is a function then the function will be called with the event
 * If track is an object then the object will override event and return,
 * and override or add additional properties
 * @param {SyntheticBaseEvent} e - click event
 * @param {*} track - track prop, boolean, function, or object
 * @param {Object} reactEvent - any react event such as onClick
 */
const handleTrackAndReactEvent = (e, track, reactEvent) => {
  if (typeof reactEvent === 'function') {
    reactEvent(e)
  }

  if (typeof track === 'boolean' && track === true) {
    return e
  } else if (typeof track === 'function') {
    return track(e)
  } else if (typeof track === 'object') {
    // Nov 30 2022
    // We can come back to this but this was the root of a circular json error
    // Reproduce by click one nav item then another.
    // For instance, Change Password > Cancel > Achieve Logo
    //return { e, ...track }
    return track
  }
}

const trackEvent = (
  analyticsInstance,
  config,
  {
    ids,
    pagePath,
    fullPagePath,
    urlParams,
    referer,
    profile,
    experiments,
    host,
    event_type = 'click',
    auth0_user_id,
    auth0_email,
    profile_id,
    ...application_data
  }
) => {
  const {
    page_name,
    event_name,
    browser_id,
    session_id,
    application_version,
    referring_url,
    url_params,
    page_title,
    page_path,
    page_url,
    full_page_url,
  } = constructTrackingObject(config, {
    pagePath,
    referer,
    urlParams,
    host,
    fullPagePath,
  })

  let event_action_override = null
  let event_name_override = null
  let form_override = null

  if (application_data.event_action) {
    event_action_override = application_data.event_action
    event_type = event_action_override
    delete application_data.event_action
  }

  if (application_data.event_name) {
    event_name_override = `${application_data.event_name} ${event_action_override}`
    delete application_data.event_name
  }

  if (application_data.href) {
    delete application_data.href
  }

  if (application_data.form) {
    form_override = application_data.form
    delete application_data.form
  }

  analyticsInstance.track(event_type, {
    ...baseObject,
    event_action: event_action_override ? event_action_override : event_type,
    page_name,
    event_name: event_name_override ? event_name_override : event_name,
    application_data: {
      ...application_data,
      application_version,
      url_params,
      page_title,
      page_path,
      page_url,
      full_page_url,
      referring_url,
    },
    ids: {
      ...ids,
      browser_id,
      session_id,
      auth0_user_id,
      auth0_email,
      profile_id,
    },
    form: form_override,
    profile,
    experiments,
  })
}

const trackPage = (
  analyticsInstance,
  config,
  {
    ids,
    pagePath,
    fullPagePath,
    urlParams,
    referer,
    profile,
    experiments,
    host,
    auth0_user_id,
    auth0_email,
    profile_id,
    pageRealPath,
    ...application_data
  }
) => {
  const {
    page_name,
    event_name,
    browser_id,
    session_id,
    application_version,
    referring_url,
    url_params,
    page_title,
    page_path,
    page_url,
    full_page_url,
  } = constructTrackingObject(config, {
    pagePath,
    referer,
    urlParams,
    host,
    fullPagePath,
    pageRealPath,
  })

  analyticsInstance.page({
    ...baseObject,
    event_action: 'page_view',
    // event-horizon v0.4.3
    // page_name ends up application_data but you can't specify it in application_data
    // if you do you will see ANALYTICS :: freedom :: page -  undefined - Page Loaded
    page_name,
    event_name,
    event_name_override: application_data?.event_name || event_name,
    application_data: {
      ...application_data,
      application_version,
      url_params,
      page_title,
      page_path,
      page_url,
      full_page_url,
      referring_url,
    },
    ids: {
      ...ids,
      browser_id,
      session_id,
      auth0_user_id,
      auth0_email,
      profile_id,
    },
    profile,
    experiments,
  })
}

const objectToQueryString = (obj) => {
  if (!obj) return ''
  return Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

const useTrackPage = (pageVariation = null, pageSectionsVariations = [], loadEventProps = {}) => {
  const enablePhoneNumbers = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS)
  const enableConsentBanner = useFeatureFlag(FEATURE_TOGGLES.ACX_WEB_BANNER_POSITION)
  const {
    state: {
      analyticsInstance,
      auth0_email,
      auth0_user_id,
      config,
      isPageLoaded,
      profile_id,
      trackingData,
      trackPageComplete,
    },
    dispatch,
  } = useContext(AnalyticsContext)
  const sessionStartEventsTracked = Cookies.get(ANALYTICS.SESSION_START_EVENTS_TRACKED)

  // Ref to store experiments without triggering re-renders and also allows the array to persist across multiple re-renders
  const experimentsRef = useRef([])
  // Helper function to add an experiment track object, avoiding duplicates
  const addExperiment = (experimentTrackObj) => {
    // Check if the experiment object already exists in experimentsRef
    const exists = experimentsRef.current.some(
      (exp) => exp.experiment_id === experimentTrackObj.experiment_id
    )

    // Only add the experiment object if it doesn't already exist
    if (!exists) {
      experimentsRef.current.push(experimentTrackObj)
    }
  }

  useEffect(() => {
    if (!sessionStartEventsTracked) {
      Cookies.set(ANALYTICS.SESSION_START_EVENTS_TRACKED, true)
    }
  }, [sessionStartEventsTracked])
  // If the  phone numbers experiment is on, not unassigned or off and the sessionStartEventsTracked
  // is unassigned (which means it's the first page load of the session). Adds experiment data for
  // page load
  if (shouldTrackSessionExperiment(enablePhoneNumbers)) {
    addExperiment(
      createExperimentObject(
        PHONE_NUMBERS_NAME_EXPERIMENT,
        FEATURE_TOGGLES.ACX_WEB_ENABLE_PHONE_NUMBERS,
        enablePhoneNumbers,
        'start'
      )
    )
  }

  if (shouldTrackSessionExperiment(enableConsentBanner)) {
    addExperiment(
      createExperimentObject(
        CONSENT_BANNER_NAME_EXPERIMENT,
        FEATURE_TOGGLES.ACX_WEB_BANNER_POSITION,
        enableConsentBanner,
        'start'
      )
    )
  }

  // Adds experiment data for page load if there is a page level experiment
  if (shouldTrackPageLevelExperiment(pageVariation?.id)) {
    addExperiment(
      createExperimentObject(
        pageVariation?.name,
        pageVariation?.id,
        pageVariation?.variation,
        'start'
      )
    )
  } else {
    pageSectionsVariations?.forEach((pageSection) => {
      // filter out any especial experiments such as session based start experiments
      if (!SESSION_BASED_FEATURE_TOGGLES.includes(pageSection?.id)) {
        addExperiment(
          createExperimentObject(
            pageSection?.name,
            pageSection?.id,
            pageSection?.variation,
            'start'
          )
        )
      }
    })
  }

  const router = useRouter()
  // remove proceeding slash and ensure pathname does not include query string
  const pagePath = getPagePath(router.asPath)
  const pageRealPath = getPagePath(router.pathname)

  const urlParams = objectToQueryString(router.query)
  const { asPath: fullPagePath } = router

  useEffect(() => {
    // Reset the loaded flag to ensure navigating to new pages gets updated page event data
    dispatch({ type: 'SET_PAGE_STATE_LOADING' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPagePath])

  // This useEffect hook intends to call the tracking event after  will be called in three cases:
  // 1. on initial app render,
  // 2. when client-side navigating to a new route
  // 3. when page loaded status changes
  useEffect(() => {
    const referer = document.referrer
    const host = location.host
    const trackPageProps = {
      event_type: 'page',
      experiments: experimentsRef.current,
      fullPagePath,
      host,
      pagePath,
      pageRealPath,
      referer,
      urlParams,
      ...loadEventProps,
    }

    waitForTealium(() => {
      !isPageLoaded &&
        dispatch({
          type: 'SET_PAGE_STATE_LOADED',
          payload: {
            trackingData: { referer, host, pagePath, urlParams, fullPagePath },
          },
        })

      if (isPageLoaded && !trackPageComplete) {
        trackPage(analyticsInstance, config, {
          ...trackingData,
          auth0_email,
          auth0_user_id,
          profile_id,
          ...trackPageProps,
        })

        dispatch({ type: 'SET_TRACK_PAGE_COMPLETE' })
        Cookies.set(ANALYTICS.LAST_PAGE_LEVEL_EXPERIMENT, pageVariation?.id)
        // Clear experimentsRef after the event fires
        experimentsRef.current = []
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullPagePath, isPageLoaded, trackPageComplete])
}

export {
  handleTrackAndReactEvent,
  getPagePath,
  objectToQueryString,
  trackEvent,
  trackPage,
  useTrackPage,
  waitForTealium,
}
